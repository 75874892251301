const RightArrowAcute = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width={props.width ? props.width : 9}
        height={props.height ? props.height : 14}
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L7 6.63442L1 12.9731" stroke={props.stroke ? props.stroke : "#00AA89"} strokeWidth="1.5" />
    </svg>
)
export default RightArrowAcute
