import { theme } from "@/styles/theme"
import styled, { css } from "styled-components"

type TextProps = {
    color?: keyof typeof Colors
    padding?: string
    font?: keyof typeof Fonts
    uppercase?: boolean
    capitalize?: boolean
    justify?: "justify" | "center" | "start" | "end"
    $scale?: boolean
}

const Colors = {
    blue100: theme.palette.blue["100"],
    blue200: theme.palette.blue["200"],
    blue300: theme.palette.blue["300"],
    blue400: theme.palette.blue["400"],
    blue500: theme.palette.blue["500"],
    blue600: theme.palette.blue["600"],
    gray100: theme.palette.gray["100"],
    gray200: theme.palette.gray["200"],
    gray300: theme.palette.gray["300"],
    dove300: theme.palette.dove300,
    dove400: theme.palette.dove400,
    dove500: theme.palette.dove500,
    green600: theme.palette.green600,
    darkgray: theme.palette.custom.darkgray,
    lightblack: theme.palette.custom.lightblack,
    white: theme.palette.custom.white,
    black: theme.palette.custom.black,
    violet800: theme.palette.violet800,
    black300: theme.palette.black300,
    black400: theme.palette.black400,
    black500: theme.palette.black500,
}
const Fonts = {
    h1: {
        family: "Rubik, sans-serif",
        weight: 400,
        fontSize: 42,
        lineHeight: 1.1,
        mobileFontSizes: 36,
        mobileLineHeight: 1.1,
    },
    h2: {
        family: "Rubik, sans-serif",
        weight: 400,
        fontSize: 36,
        lineHeight: 1.1,
        mobileFontSizes: 28,
        mobileLineHeight: 1.1,
    },
    h3: {
        family: "Rubik, sans-serif",
        weight: 400,
        fontSize: 24,
        lineHeight: 1.1,
        mobileFontSizes: 24,
        mobileLineHeight: 1.1,
    },
    h4: {
        family: "Rubik, sans-serif",
        weight: 500,
        fontSize: 18,
        lineHeight: 1.2,
        mobileFontSizes: 18,
        mobileLineHeight: 1.2,
    },
    h5: {
        family: "Rubik, sans-serif",
        weight: 500,
        fontSize: 16,
        lineHeight: 1.2,
        mobileFontSizes: 16,
        mobileLineHeight: 1.2,
    },
    t1: {
        family: "Rubik, sans-serif",
        weight: 400,
        fontSize: 16,
        lineHeight: 1.2,
        mobileFontSizes: 16,
        mobileLineHeight: 1.2,
    },
    t1b: {
        family: "Rubik, sans-serif",
        weight: 500,
        fontSize: 16,
        lineHeight: 1.2,
        mobileFontSizes: 16,
        mobileLineHeight: 1.2,
    },
    t2: {
        family: "Rubik, sans-serif",
        weight: 400,
        fontSize: 14,
        lineHeight: 1.2,
        mobileFontSizes: 14,
        mobileLineHeight: 1.2,
    },
    t2b: {
        family: "Rubik, sans-serif",
        weight: 500,
        fontSize: 14,
        lineHeight: 1.2,
        mobileFontSizes: 14,
        mobileLineHeight: 1.2,
    },
    t3: {
        family: "Rubik, sans-serif",
        weight: 400,
        fontSize: 12,
        lineHeight: 1.3,
        mobileFontSizes: 12,
        mobileLineHeight: 1.3,
    },
    t3b: {
        family: "Rubik, sans-serif",
        weight: 500,
        fontSize: 12,
        lineHeight: 1.3,
        mobileFontSizes: 12,
        mobileLineHeight: 1.3,
    },
    t4: {
        family: "Rubik, sans-serif",
        weight: 400,
        fontSize: 11,
        lineHeight: 1.3,
        mobileFontSizes: 11,
        mobileLineHeight: 1.3,
    },
    t4b: {
        family: "Rubik, sans-serif",
        weight: 500,
        fontSize: 11,
        lineHeight: 1.3,
        mobileFontSizes: 11,
        mobileLineHeight: 1.3,
    },
    p1: {
        family: "Rubik, sans-serif",
        weight: 400,
        fontSize: 16,
        lineHeight: 1.4,
        mobileFontSizes: 16,
        mobileLineHeight: 1.4,
    },
    p2: {
        family: "Rubik, sans-serif",
        weight: 400,
        fontSize: 14,
        lineHeight: 1.4,
        mobileFontSizes: 14,
        mobileLineHeight: 1.4,
    },
}

export const Text2 = styled.span<TextProps>`
    font-family: ${({ font }) => Fonts[font].family};
    font-weight: ${({ font }) => Fonts[font].weight};
    font-size: ${({ font }) => Fonts[font].fontSize}px;
    color: ${({ color }) => Colors[color]};
    padding: ${({ padding }) => (padding ? padding : "0px")};
    text-transform: ${({ uppercase, capitalize }) => (uppercase ? "uppercase" : capitalize ? "capitalize" : "none")};
    display: inline-flex;
    align-items: center;
    align-content: center;
    line-height: ${({ font }) => Fonts[font].lineHeight};
    text-align: ${({ justify }) => (justify ? justify : "start")};

    ${({ font, $scale, theme }) =>
        $scale
            ? css`
                  @media (max-width: ${theme.breakpoints.md}px) {
                      font-size: ${Fonts[font].mobileFontSizes}px;
                      line-height: ${Fonts[font].mobileLineHeight};
                  }
              `
            : ""};
`

Text2.defaultProps = { $scale: false, font: "t1", color: "black500" }
