import { useTranslation } from "react-i18next"
import Head from "next/head"

type Title =
    | "login"
    | "resetPassword"
    | "tests"
    | "test"
    | "cantdidate"
    | "prepare"
    | "yourTest"
    | "thanks"
    | "onboarding"
    | "newTest"
    | "404"
    | "setPassword"
    | "heroify"

export const CustomHelmet = ({ extraData, title = "heroify" }: { extraData?: string; title?: Title }) => {
    const { t, ready } = useTranslation("metaTitles")

    return (
        <Head>
            <link rel="shortcut icon" href="/favicon.ico" />
            {ready ? (
                <>
                    <title>{!extraData ? t(title) : t(title, { variable: extraData })}</title>
                    <meta name="og:title" content={!extraData ? t(title) : t(title, { variable: extraData })} />
                </>
            ) : (
                <>
                    <title>Heroify</title>
                    <meta name="og:title" content="Heroify" />
                </>
            )}
        </Head>
    )
}
