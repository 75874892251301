const LogoIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width={props.width ? props.width : 95}
        height={props.height ? props.height : 20}
        viewBox="0 0 95 20"
        {...props}>
        <path
            data-name="Path 240"
            d="M14.074 7.407a8.353 8.353 0 0 0 3.7.833A8.582 8.582 0 0 0 26.389 0h-8.148a.091.091 0 0 0-.093.093 9.449 9.449 0 0 1-4.074 7.314Z"
            fill="#00aa89"
        />
        <path
            data-name="Path 241"
            d="M12.315 9.907a8.353 8.353 0 0 0-3.7-.833A8.582 8.582 0 0 0 0 17.315h8.148a10.3 10.3 0 0 1 4.167-7.408Z"
            fill="#00aa89"
        />
        <path
            data-name="Path 242"
            d="M17.778 9.074a9.84 9.84 0 0 1-4.63-1.2l-.093-.093A10.743 10.743 0 0 1 11.112 6.3 9.4 9.4 0 0 1 8.334 0H.093v13.8a9.577 9.577 0 0 1 8.7-5.556 9.84 9.84 0 0 1 4.63 1.2l.093.093a10.743 10.743 0 0 1 1.944 1.481 9.4 9.4 0 0 1 2.778 6.3h8.241v-13.8a9.68 9.68 0 0 1-8.701 5.556Z"
            fill="#002ccc"
        />
        <path
            data-name="Path 243"
            d="M33.981 7.13h5.741V1.852h2.685v13.519h-2.685V9.352h-5.741v6.019h-2.685V1.852h2.685Z"
            fill={props.fill ? props.fill : "#1d1976"}
        />
        <path
            data-name="Path 244"
            d="M53.333 12.962a4.95 4.95 0 0 1-7.685 1.574 4.284 4.284 0 0 1-1.389-3.425 4.749 4.749 0 0 1 1.3-3.43 4.405 4.405 0 0 1 3.333-1.2 4.133 4.133 0 0 1 3.426 1.481 5.216 5.216 0 0 1 1.019 3.426v.185h-6.578a2.562 2.562 0 0 0 .463 1.481 2.128 2.128 0 0 0 1.759.833 2.2 2.2 0 0 0 2.037-1.2Zm-2.315-3.148a2.076 2.076 0 0 0-2.13-1.759 1.987 1.987 0 0 0-2.037 1.759Z"
            fill={props.fill ? props.fill : "#1d1976"}
        />
        <path
            data-name="Path 245"
            d="M55.278 6.667h2.5v1.3a2.789 2.789 0 0 1 1.107-1.208 2.349 2.349 0 0 1 1.481-.37 2.858 2.858 0 0 1 1.019.185l-.37 2.315a2.316 2.316 0 0 0-1.2-.37 1.964 1.964 0 0 0-1.667.741 2.911 2.911 0 0 0-.463 1.944v4.259h-2.5v-8.8Z"
            fill={props.fill ? props.fill : "#1d1976"}
        />
        <path
            data-name="Path 246"
            d="M70.649 7.59a4.892 4.892 0 0 1 1.481 3.429 4.723 4.723 0 0 1-1.481 3.426 5.744 5.744 0 0 1-7.037 0 4.892 4.892 0 0 1-1.482-3.426 4.564 4.564 0 0 1 1.481-3.429 5.135 5.135 0 0 1 3.519-1.2 4.511 4.511 0 0 1 3.519 1.2Zm-1.111 3.426a2.433 2.433 0 0 0-2.5-2.5 2.5 2.5 0 0 0 0 5 2.433 2.433 0 0 0 2.499-2.497Z"
            fill={props.fill ? props.fill : "#1d1976"}
        />
        <path
            data-name="Path 247"
            d="M75.185 1.389a1.481 1.481 0 1 1-1.481 1.481 1.463 1.463 0 0 1 1.481-1.481Zm1.2 5.278v8.8h-2.5v-8.8Z"
            fill={props.fill ? props.fill : "#1d1976"}
        />
        <path
            data-name="Path 248"
            d="M81.296 8.704v6.667h-2.5V8.704h-.926V6.578h.926V4.167a3.918 3.918 0 0 1 1.2-3.148 3.3 3.3 0 0 1 2.13-.741 3.092 3.092 0 0 1 1.389.278v2.222a1.617 1.617 0 0 0-1.111-.37 1.025 1.025 0 0 0-.926.463 3.246 3.246 0 0 0-.278 1.481v2.126h2.315v2.13h-2.222Z"
            fill={props.fill ? props.fill : "#1d1976"}
        />
        <path
            data-name="Path 249"
            d="m88.426 14.074-4.167-7.5h2.87l2.685 5 2.407-5h2.778L87.869 20h-2.778Z"
            fill={props.fill ? props.fill : "#1d1976"}
        />
    </svg>
)
export default LogoIcon
